@import './assets/index.scss';

.App {
  text-align: center;
}

.App-header {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}
